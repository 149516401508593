import {Box, BoxProps, Tooltip} from '@chakra-ui/react';
import React from 'react';
import {useDrag} from 'react-dnd';
import {ScoringCategoryColors} from './theme';
import {
  KeyplayScoringSignal,
  ScoringSignal,
  ScoringCategory,
  ResolvedScoringSignal,
} from '../shared/signals';
import {Draggable} from '@carbon/icons-react';
import {useScoringSignalResolver} from '../hooks/scoringSignal';
import {captureMessage} from '@sentry/react';
import {ChakraModel} from './Icons/Carbon';
import {assertNever} from '../shared/util';
import {findSignalGroup} from '../shared/signalGroups';
import {Image} from '@chakra-ui/react';
import {useCustomer} from '../hooks/api/metadata';

const DraggableIcon = ({
  scoringSignal,
  ...props
}: BoxProps & {
  scoringSignal: ResolvedScoringSignal;
}) => {
  const {source} = scoringSignal;
  const customer = useCustomer();

  switch (source) {
    case 'keyplay': {
      const signalGroup = findSignalGroup({
        signal: scoringSignal.id,
        signalDefinitions: [],
      });
      return signalGroup === 'fundingAmount' ||
        signalGroup === 'lastFundingDate' ? (
        <Tooltip hasArrow label="Powered by Crunchbase" placement="top">
          <Image
            transform="translateY(-0.5px)"
            src="/crunchbase.png"
            alt="Crunchbase logo"
            boxSize="16px"
            {...props}
          />
        </Tooltip>
      ) : null;
    }
    case 'custom':
      return (
        <Tooltip hasArrow label={`Custom for ${customer.name}`} placement="top">
          <ChakraModel transform="translateY(-1px)" {...props} />
        </Tooltip>
      );
    default:
      assertNever(source);
  }
};

/*
 * A single draggable tag, used by the model builder.
 */
function _DraggableSignal({
  scoringSignal,
  hide,
  weight,
  ...props
}: BoxProps & {
  scoringSignal: ResolvedScoringSignal;
  hide?: boolean;
  weight?: number;
}) {
  const {id, category, label} = scoringSignal;
  const dim = weight === 0;

  const [{isDragging}, drag] = useDrag(() => ({
    type: category,
    item: {signal: id, category},
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const hasWeight = (weight ?? 0) > 1;

  // the z-index setting prevents a white background from showing up on the ghost image of
  // the drag and drop
  return (
    <Box
      position="relative"
      borderColor="transparent"
      borderWidth="1px"
      bg={`${ScoringCategoryColors[category]}.50`}
      color={`${ScoringCategoryColors[category]}.400`}
      display={hide ? 'none' : 'inline-flex'}
      alignItems="center"
      fontSize="sm"
      px={'15px'}
      py={'7px'}
      borderRadius="40px"
      cursor={isDragging ? 'grabbing' : 'grab'}
      role="Handle"
      ref={drag}
      style={{opacity: isDragging || dim ? 0.5 : 1}}
      zIndex={1}
      {...props}
    >
      <Draggable />
      <Box ml={3}>{label}</Box>
      <DraggableIcon ml={2} mr={0.5} scoringSignal={scoringSignal} />
      {hasWeight && (
        <Box
          position="absolute"
          top={0}
          right={0}
          transform="translate(50%, -50%)"
          bg={`${ScoringCategoryColors[category]}.400`}
          color="white"
          borderRadius="full"
          fontSize="2xs"
          mr={1}
          mt={1}
          px={1}
          py={0.5}
        >
          {weight}x
        </Box>
      )}
    </Box>
  );
}

export type DragItemType = {
  signal: KeyplayScoringSignal;
  category: ScoringCategory;
};

export const DraggableSignal = React.memo(
  ({
    signal,
    hide = false, // hides the tag
    weight,
    ...props
  }: BoxProps & {
    signal: ScoringSignal;
    hide?: boolean;
    weight?: number;
  }) => {
    const scoringSignalResolver = useScoringSignalResolver();
    const scoringSignal = scoringSignalResolver(signal);
    if (!scoringSignal) {
      captureMessage(`unknown draggable signal ${signal}`, 'error');
      return null;
    }

    return (
      <_DraggableSignal
        scoringSignal={scoringSignal}
        hide={hide}
        weight={weight}
        {...props}
      />
    );
  }
);
DraggableSignal.displayName = 'DraggableSignal';

/* Non-draggable version of above, renders a single signal */

interface SimpleSignalProps extends BoxProps {
  signal: ScoringSignal;
  weight?: number;
}

export const SimpleSignal = ({signal, weight, ...props}: SimpleSignalProps) => {
  const scoringSignalResolver = useScoringSignalResolver();
  const resolvedSignal = scoringSignalResolver(signal);
  if (!resolvedSignal) {
    return null;
  }

  const {category, label} = resolvedSignal;
  return (
    <Box
      position="relative"
      bg={`${ScoringCategoryColors[category]}.50`}
      color={`${ScoringCategoryColors[category]}.400`}
      alignItems="center"
      fontSize="12px"
      px={2}
      py={1}
      borderRadius="40px"
      whiteSpace="nowrap"
      {...props}
    >
      {label}
      {(weight ?? 0) > 1 && (
        <Box
          position="absolute"
          top={0}
          right={0}
          transform="translate(50%, -50%)"
          bg={`${ScoringCategoryColors[category]}.400`}
          color="white"
          borderRadius="full"
          fontSize="10px"
          mr={0}
          mt={0}
          px={1}
          py={0.5}
        >
          {weight}x
        </Box>
      )}
    </Box>
  );
};
