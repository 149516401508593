import {ObjectId} from 'bson';
import _ from 'lodash';
import {z} from 'zod';

export const MinDate = new Date(-8640000000000000);

// https://stackoverflow.com/questions/70415797/generating-typescript-types-from-a-nested-object
export type AllKeysOf<T> = T extends object ? keyof T : never;
export type AllValuesOf<T> = T extends object ? T[keyof T] : never;

export type StrictOmit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type StrictExclude<T, U extends T> = T extends U ? never : T;

// https://github.com/sindresorhus/type-fest
export type KeysOfUnion<T> = T extends unknown ? keyof T : never;
export type DistributedPick<T, K extends KeysOfUnion<T>> = T extends unknown
  ? Pick<T, Extract<K, keyof T>>
  : never;
export type DistributedOmit<T, K extends KeysOfUnion<T>> = T extends unknown
  ? Omit<T, K>
  : never;

export type WithRequired<T, K extends keyof T> = T & {[P in K]-?: T[P]};
export type MakeOptional<T, K extends keyof T> = StrictOmit<T, K> & {
  [P in K]?: T[P];
};

export type Writeable<T> = {-readonly [P in keyof T]: T[P]};

export type WithId<T> = T & {
  _id: ObjectId;
};

export type WithTaskId<T> = T & {
  taskId: ObjectId;
};

export type WithTimestamp<T> = T & {
  timestamp: Date;
};

export type SearchableValue = {
  key: string;
  value:
    | string
    | number
    | boolean
    | ObjectId
    | string[]
    | number[]
    | ObjectId[];
};

// Subset is Partial for nested objects
// https://grrr.tech/posts/2021/typescript-partial/
export type Subset<K> = {
  [attr in keyof K]?: K[attr] extends object
    ? Subset<K[attr]>
    : K[attr] extends object | null
      ? Subset<K[attr]> | null
      : K[attr] extends object | null | undefined
        ? Subset<K[attr]> | null | undefined
        : K[attr];
};

// Because https://dev.to/mapleleaf/indexing-objects-in-typescript-1cgi
// `PropertyKey` is short for "string | number | symbol"
// since an object key can be any of those types, our key can too
// in TS 3.0+, putting just "string" raises an error
export function hasKey<O extends object>(
  obj: O,
  key: PropertyKey
): key is keyof O {
  return key in obj;
}

// for loops on objects will give you the string type for keys, since
// the object might inherit from the type you're looping over and
// have additional keys (structural typing.) For those cases you
// can either iterate over a separate list of keys (e.g. if your keys
// are scoring categories just iterate over ScoringCategories) or use
// this.
export function keys<T extends object>(o: T): Array<keyof T> {
  return Object.keys(o) as Array<keyof T>;
}

// https://stackoverflow.com/questions/56832826/losing-enum-type-information-in-for-in-loop
export function entries<T extends object>(o: T): [keyof T, T[keyof T]][] {
  return Object.entries(o) as [keyof T, T[keyof T]][];
}

export function fromEntries<T extends PropertyKey, V>(
  entries: [T, V][]
): {[K in T]: V} {
  return Object.fromEntries(entries) as {[K in T]: V};
}

// Initialize key -> defaultValue object from array of keys
export function createDictionaryFromKeys<K extends string, V>(
  keys: K[],
  defaultValue: V
): Record<K, V> {
  const keysArray = keys.map((key) => ({
    [key]: _.cloneDeep(defaultValue),
  }));
  return Object.assign({}, ...keysArray);
}

// https://github.com/microsoft/TypeScript/issues/30542#issuecomment-475646727
export type GuardedType<T> = T extends (x: unknown) => x is infer U ? U : never;
export type Extends<T, K> = T extends K ? T : never;

// Helper function that creates a new Record with its keys renamed by the provided map.
export function remapKeys<OldKey extends string, NewKey extends string, Value>(
  record: Record<OldKey, Value>,
  mapFn: (key: OldKey) => NewKey
): Record<NewKey, Value> {
  return entries(record).reduce(
    (record, [key, value]) => {
      record[mapFn(key)] = value;
      return record;
    },
    {} as Record<NewKey, Value>
  );
}

/**
 * Helper function to convert an array to a record.
 */
export function fromEntriesMapped<T, K extends PropertyKey, V>(
  arr: T[],
  mapper: (value: T) => [K, V]
): {[key in K]: V} {
  return Object.fromEntries(arr.map((value) => mapper(value))) as {
    [key in K]: V;
  };
}

/**
 * Helper function to convert an array to a record using the specified key.
 */
export function fromEntriesKey<
  T extends Record<K, PropertyKey>,
  K extends keyof T,
>(arr: T[], key: K): {[key in T[K]]: T} {
  return fromEntriesMapped(arr, (value) => [value[key], value]);
}

export function toTitleCase(str: string): string {
  return str
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(' ');
}

// https://github.com/colinhacks/zod/issues/831
export function isValidZodEnumArray<T extends string>(
  literals: readonly T[]
): literals is [T, T, ...T[]] {
  return literals.length >= 2;
}
export function zodEnum<T extends string>(values: readonly T[]) {
  if (!isValidZodEnumArray(values)) {
    throw new Error('Must have at least 2 values');
  }
  return z.enum(values);
}

export function assertNever(x: never): never {
  throw new Error(`Unexpected value: ${x}`);
}

export function typeguardFromArray<T>(e: ReadonlyArray<T>) {
  return (token: unknown): token is T => e.includes(token as T);
}

// Removing undefined properties from an object can be useful e.g. when we want to
// compare objects without having the presence/absence of explicit undefined values
// affecting the comparison.
export function removeUndefinedValues<T extends object>(obj: T): T {
  return _.pickBy(obj, (value) => !_.isUndefined(value)) as T;
}

export function removeLeadingSlash(url: string): string {
  return url.startsWith('/') ? url.slice(1) : url;
}

export function addTrailingSlash(url: string): string {
  return url.endsWith('/') ? url : url + '/';
}

export function swallowErrors<T>(fn: () => T): T | undefined {
  try {
    return fn();
  } catch (e) {
    return undefined;
  }
}

export function filterNull<T>(arr: (T | undefined | null)[]): T[] {
  return arr.filter((item): item is T => {
    return item !== null && item !== undefined;
  });
}

export function parseIntOrUndefined(value: string) {
  const parsed = parseInt(value);
  return isNaN(parsed) ? undefined : parsed;
}

export function convertToAlphanumeric(str?: string | null) {
  if (!str) {
    return '';
  }
  return str.replace(/[^a-zA-Z\d]+/g, '').trim();
}

export function dedupeStringValues<T>(arr: T[]): T[] {
  return _.uniqBy(arr, String);
}

// Recursively remove undefined keys from objects
export function omitUndefinedDeep(value: unknown): unknown {
  if (!_.isPlainObject(value)) {
    return value;
  }

  return _.transform(
    value as Record<string, unknown>,
    (result: Record<string, unknown>, val, key) => {
      if (val !== undefined) {
        result[key] = omitUndefinedDeep(val);
      }
    }
  );
}
export function assertNotNullable<T>(
  val: T | undefined | null,
  message?: string
): asserts val is NonNullable<T> {
  if (val === undefined || val === null) {
    throw new Error(message || 'assertExists');
  }
}

export function assertNullOrUndefined<T>(
  val: T | undefined | null
): asserts val is null | undefined {
  if (val) {
    throw new Error('assertNullOrUndefined');
  }
}

// TODO: rename to something like ensureExists or throwIfNull?
export function assertExists<T>(
  val: T | undefined | null,
  message?: string
): T {
  assertNotNullable(val, message);
  return val;
}
