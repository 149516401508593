import React, {useState} from 'react';
import {useSignalBuilderStore} from './SignalBuilder.state';
import {Box, Flex, GridItem, Input, Text} from '@chakra-ui/react';
import Select from 'react-select';
import _ from 'lodash';
import {assertNever} from '../../../shared/util';
import {
  AccountFieldOrCustomField,
  RangeEvalFn,
} from '../../../shared/signalDefinition';
import {LabelInput} from './helpers';
import {useResolvedField} from '../../../hooks/fields';

type Operator = 'greaterThan' | 'lessThan' | 'between';
const operatorOptions: {value: Operator; label: string}[] = [
  {
    value: 'between',
    label: 'Is between',
  },
  {
    value: 'greaterThan',
    label: 'Is >=',
  },
  {
    value: 'lessThan',
    label: 'Is <=',
  },
] as const;

const generateLabel = (
  operator: Operator,
  evalFn: RangeEvalFn,
  fieldLabel: string
) => {
  const formatNumber = (num: number) =>
    // HACK: can make this more generic if needed
    evalFn.field === 'totalFundingRaised'
      ? new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 0,
        }).format(num)
      : new Intl.NumberFormat('en-US').format(num);

  return operator === 'between' &&
    evalFn.lowerBound !== undefined &&
    evalFn.upperBound !== undefined
    ? `${fieldLabel}: ${formatNumber(evalFn.lowerBound)}-${formatNumber(evalFn.upperBound)}`
    : operator === 'greaterThan' && evalFn.lowerBound !== undefined
      ? `${fieldLabel}: ${formatNumber(evalFn.lowerBound)}+`
      : operator === 'lessThan' && evalFn.upperBound !== undefined
        ? `${fieldLabel}: ${formatNumber(evalFn.upperBound)} or less`
        : '';
};

export const RangeSignalBuilder = ({
  field,
}: {
  field: AccountFieldOrCustomField;
}) => {
  const {label: fieldLabel} = useResolvedField(field);
  const {setLabel, evalFn, setEvalFn} = useSignalBuilderStore();

  // This check ensures that we have access to the range-specific fields (lowerBound, upperBound)
  if (evalFn && evalFn.type !== 'range') {
    throw new Error('Invalid evalFn type');
  }

  const [operator, setOperator] = useState<Operator>('between');
  const [lowerBound, setLowerBound] = useState<number | undefined>(
    evalFn?.lowerBound
  );
  const [upperBound, setUpperBound] = useState<number | undefined>(
    evalFn?.upperBound
  );

  const update = ({
    operator,
    lowerBound,
    upperBound,
  }: {
    operator: Operator;
    lowerBound: number | undefined;
    upperBound: number | undefined;
  }) => {
    setOperator(operator);
    setLowerBound(lowerBound);
    setUpperBound(upperBound);

    // If the eval fn is valid, update the store
    if (
      (operator === 'between' &&
        lowerBound !== undefined &&
        upperBound !== undefined) ||
      (operator === 'greaterThan' && lowerBound !== undefined) ||
      (operator === 'lessThan' && upperBound !== undefined)
    ) {
      const updatedEvalFn = {
        type: 'range',
        field,
        lowerBound,
        upperBound,
      } as const;

      setEvalFn(updatedEvalFn);
      setLabel(generateLabel(operator, updatedEvalFn, fieldLabel));
    } else {
      // Otherwise, reset the eval fn and label so we know the signal is invalid
      setEvalFn(null);
      setLabel('');
    }
  };

  return (
    <>
      <GridItem as={Flex} alignItems="center" flexDirection="row" gap="4">
        <Box w="170px">
          <Select
            isMulti={false}
            options={operatorOptions}
            value={operatorOptions.find((option) => option.value === operator)}
            onChange={(newValue) => {
              if (!newValue) {
                return;
              }

              update({
                operator: newValue.value,
                lowerBound: undefined,
                upperBound: undefined,
              });
            }}
          />
        </Box>
        <Flex alignItems="center" direction="row" flex="1" gap="4">
          {operator === 'between' ? (
            <>
              <Input
                onChange={(e) => {
                  const value = e.target.value;
                  update({
                    operator,
                    lowerBound: value !== '' ? _.toNumber(value) : undefined,
                    upperBound,
                  });
                }}
                type="number"
                value={lowerBound ?? ''}
              />
              <Text textColor="kgray.300">and</Text>
              <Input
                onChange={(e) => {
                  const value = e.target.value;
                  update({
                    operator,
                    lowerBound,
                    upperBound: value !== '' ? _.toNumber(value) : undefined,
                  });
                }}
                type="number"
                value={upperBound ?? ''}
              />
            </>
          ) : operator === 'greaterThan' ? (
            <Input
              onChange={(e) => {
                const value = e.target.value;
                update({
                  operator,
                  lowerBound: value !== '' ? _.toNumber(value) : undefined,
                  upperBound,
                });
              }}
              type="number"
              value={lowerBound ?? ''}
            />
          ) : operator === 'lessThan' ? (
            <Input
              onChange={(e) => {
                const value = e.target.value;
                update({
                  operator,
                  lowerBound,
                  upperBound: value !== '' ? _.toNumber(value) : undefined,
                });
              }}
              type="number"
              value={upperBound ?? ''}
            />
          ) : (
            assertNever(operator)
          )}
        </Flex>
      </GridItem>
      <GridItem alignSelf="center">
        <LabelInput />
      </GridItem>
    </>
  );
};
