import {ObjectId} from 'bson';
import {
  GetFieldDefinitionAnalysisRunResults,
  GetLatestFieldDefinitionAnalysisRun,
  UpdateFieldDefinitionAnalysisRun,
} from '../../shared/api/definitions';
import {createApiQuery, useApiMutation} from './api';
import {useQueryClient} from '@tanstack/react-query';

export const FieldDefinitionAnalysisRunQueryKeys = {
  all: ['fieldDefinitionAnalysisRun'],
  field: ({
    fieldDefinitionId,
    versionId,
  }: {
    fieldDefinitionId: ObjectId;
    versionId: ObjectId;
  }) => [
    ...FieldDefinitionAnalysisRunQueryKeys.all,
    'field',
    fieldDefinitionId.toString(),
    versionId.toString(),
  ],
  results: (fieldDefinitionRunId: ObjectId) => [
    ...FieldDefinitionAnalysisRunQueryKeys.all,
    'results',
    fieldDefinitionRunId.toString(),
  ],
};

export const useLatestFieldDefinitionAnalysisRun = createApiQuery(
  '/latestFieldDefinitionAnalysisRun',
  GetLatestFieldDefinitionAnalysisRun,
  ({fieldDefinitionId, versionId}) =>
    FieldDefinitionAnalysisRunQueryKeys.field({fieldDefinitionId, versionId}),
  {
    refetchInterval: (query) =>
      query.state.data?.results.some(({status}) => status === 'running')
        ? 5_000
        : 0,
  }
);

export const useUpdateFieldDefinitionAnalysisRun = () => {
  const queryClient = useQueryClient();

  return useApiMutation(
    '/updateFieldDefinitionAnalysisRun',
    UpdateFieldDefinitionAnalysisRun,
    {
      onSuccess: (updatedRun) => {
        if (!updatedRun) {
          return;
        }

        queryClient.setQueryData(
          FieldDefinitionAnalysisRunQueryKeys.field({
            fieldDefinitionId: updatedRun.fieldDefinitionId,
            versionId: updatedRun.versionId,
          }),
          updatedRun
        );
      },
    }
  );
};

export const useGetAnalysisRunResults = createApiQuery(
  '/fieldDefinitionAnalysisRunResults',
  GetFieldDefinitionAnalysisRunResults,
  ({fieldDefinitionRunId}) =>
    FieldDefinitionAnalysisRunQueryKeys.results(fieldDefinitionRunId)
);
