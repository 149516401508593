import {useAiFieldBuilderStore} from '../AiFieldBuilder.state';
import {useLatestFieldDefinitionAnalysisRun} from '../../../../hooks/api/fieldDefinitionAnalysisRun';
import {useSelectedVersionStore} from '../SelectedVersion.state';

export function useIsAnalysisRunInProgress() {
  const {serverState} = useAiFieldBuilderStore();
  const {selectedVersionId} = useSelectedVersionStore();
  const {data: analysisRun} = useLatestFieldDefinitionAnalysisRun({
    fieldDefinitionId: serverState.id,
    versionId: selectedVersionId,
  });

  return analysisRun?.results.some((result) => result.status === 'running');
}
