import React from 'react';
import {Input, Text, TextProps} from '@chakra-ui/react';
import {useSignalBuilderStore} from './SignalBuilder.state';

export const Label = ({
  children,
  ...props
}: React.PropsWithChildren<TextProps>) => (
  <Text
    fontSize="sm"
    textColor="kgray.300"
    textTransform="uppercase"
    {...props}
  >
    {children}
  </Text>
);

export const LabelInput = () => {
  const {label, setLabel} = useSignalBuilderStore();
  return <Input onChange={(e) => setLabel(e.target.value)} value={label} />;
};
