import React from 'react';
import {TierCounts} from '../../../shared/scoring';
import {TierPieChart} from '../../Market/MarketBuilder/TierPieChart';
import {Flex, Circle, Divider, Box} from '@chakra-ui/react';
import {calculateNormalizedPercentage} from '../../../lib/helpers';
import {entries} from '../../../shared/util';

const SampleTestListResultRow = ({
  testName,
  tierCounts,
}: {
  testName: string;
  tierCounts: TierCounts;
}) => {
  const valueCounts = entries(tierCounts);
  return (
    <Flex
      alignItems="center"
      fontSize="sm"
      gap="4"
      justifyContent="space-between"
      p="4"
    >
      <Flex direction="column" gap="2">
        <Box fontWeight="500">{testName}</Box>
        <Flex gap="6">
          {valueCounts.map(([tier], index) => (
            <Flex key={tier} direction="column" fontSize="xs">
              <Box textColor="kgray.300">{tier}</Box>
              <Box>
                {
                  calculateNormalizedPercentage({
                    targetValue: tier,
                    valueCounts,
                    isLastValue: index === valueCounts.length - 1,
                  }).percentage
                }
                %
              </Box>
            </Flex>
          ))}
        </Flex>
      </Flex>
      <TierPieChart tierCounts={tierCounts} />
    </Flex>
  );
};

const SampleTestListResults = () => {
  return (
    <Flex
      boxSize="300px"
      direction="column"
      justifyContent="center"
      position="relative"
    >
      <Circle
        bgColor="kgray.100"
        size="80%"
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      />
      <Flex bgColor="kgray.50" boxShadow="lg" direction="column" zIndex={1}>
        <SampleTestListResultRow
          testName="Top Customers"
          tierCounts={{A: 51, B: 34, C: 15, D: 0}}
        />
        <Divider />
        <SampleTestListResultRow
          testName="Disqualified Leads"
          tierCounts={{A: 9, B: 13, C: 21, D: 57}}
        />
      </Flex>
    </Flex>
  );
};

export const EmptyTestListsContainer = () => {
  return (
    <Flex
      alignItems="center"
      border="1px solid"
      borderColor="kgray.200"
      borderRadius="lg"
      direction="column"
      gap="4"
      p="40px 70px"
    >
      <SampleTestListResults />
      <Flex alignItems="center" direction="column" gap="2">
        <Box fontWeight="500">No test lists added yet</Box>
        <Box textColor="kgray.300" textAlign="center">
          Test lists help you analyze your builds across thousands of accounts.
          Use them anywhere you&apos;re customizing Keyplay &mdash; Account
          Scoring, AI Field Builder, and more.
        </Box>
      </Flex>
    </Flex>
  );
};
