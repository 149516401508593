import React from 'react';
import {Box, BoxProps, Flex} from '@chakra-ui/react';
import {WithId} from '../../../shared/util';
import {TestList} from '../../../shared/testLists';
import {useMarkets} from '../../../hooks/api/metadata';

export const TestListLabel = ({
  testList,
  boxProps,
}: {
  testList: WithId<TestList>;
  boxProps?: BoxProps;
}) => {
  const markets = useMarkets();

  let label;
  if (testList.type === 'samSample') {
    const market = markets.find((m) => m.id.equals(testList.marketId));
    label = market ? (
      <Flex gap="2">
        <Box>{testList.label}</Box>
        <Box bg="kgray.100" borderRadius="md" color="kgray.300" px="2">
          {market.label}
        </Box>
      </Flex>
    ) : (
      testList.label
    );
  } else {
    label = testList.label;
  }

  return <Box {...boxProps}>{label}</Box>;
};
