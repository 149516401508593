import React, {useCallback, useEffect, useState} from 'react';
import {
  Box,
  Button,
  chakra,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react';
import {useCreateTagMutation, useTags} from '../hooks/api/tags';
import {TagResponse} from '../shared/api/api';
import _ from 'lodash';
import {useForm} from 'react-hook-form';
import {AddFilled} from '@carbon/icons-react';
import {TagName} from '../shared/validation';
import {pluralize} from '../lib/helpers';
import {ActionContext, useAccountActions} from '../hooks/api/actions';
import {ObjectId} from 'bson';

interface AccountTagsEditorModalProps {
  isOpen: boolean;
  onClose: () => void;

  actionContext?: ActionContext;
  bulkNumberSelected?: number;
  onTagsUpdated?: (tags: ObjectId[]) => void;
  selectedTags: ObjectId[];
  tagOnly: boolean;
}

export function AccountTagsEditorModal({
  isOpen,
  onClose,
  ...rest
}: AccountTagsEditorModalProps) {
  const headerText = rest.tagOnly ? 'Tag' : 'Save & Tag';
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pb="0">{headerText}</ModalHeader>
        <ModalCloseButton />
        <ModalBody p="0">
          <AccountTagsEditorModalBody onClose={onClose} {...rest} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

const AccountTagsEditorModalBody = ({
  actionContext,
  bulkNumberSelected,
  onClose,
  onTagsUpdated,
  selectedTags,
  tagOnly,
}: Omit<AccountTagsEditorModalProps, 'isOpen'>) => {
  const tags = useTags();
  const [localSelectedTags, setLocalSelectedTags] = useState<ObjectId[]>([]);

  useEffect(() => {
    setLocalSelectedTags(selectedTags);
  }, [selectedTags]);

  const onCheckboxClicked = useCallback(
    (tag: TagResponse, checked: boolean) => {
      setLocalSelectedTags((prevState) => {
        if (checked) {
          return [...prevState, tag._id];
        } else {
          const idx = prevState.findIndex((item) => item.equals(tag._id));
          if (idx === undefined) {
            return prevState;
          }

          const updatedTags = [...prevState];
          updatedTags.splice(idx, 1);
          return updatedTags;
        }
      });
    },
    []
  );

  const accountActions = useAccountActions(actionContext);
  const updateAccount = (mode: 'tag' | 'save') => {
    if (!actionContext) {
      return;
    }

    const tagsToAdd =
      mode === 'tag'
        ? _.differenceWith(localSelectedTags, selectedTags, _.isEqual)
        : [];
    const tagsToRemove =
      mode === 'tag'
        ? _.differenceWith(selectedTags, localSelectedTags, _.isEqual)
        : [];

    accountActions.mutate(
      {
        action: {
          name: 'tag',
          tagsToAdd,
          tagsToRemove,
        },
      },
      {
        onSuccess: () => {
          onTagsUpdated?.(localSelectedTags);
          onClose();
        },
      }
    );
  };

  const isSaveButtonDisabled =
    _.xor(
      localSelectedTags.map((id) => id.toString()),
      selectedTags.map((id) => id.toString())
    ).length === 0;

  let subHeaderText = '';
  if (!bulkNumberSelected) {
    subHeaderText = 'Select tags for this account';
  } else {
    subHeaderText = pluralize(
      bulkNumberSelected,
      'selected account',
      'selected accounts'
    );
  }

  let saveButtonText = '';
  if (!tagOnly) {
    saveButtonText = 'Save + Add Tags';
  } else {
    // When in bulk selection mode, the user is adding tags to a list of accounts.
    // When in single selection mode, the user is applying all selected tags to the account.
    const tagsAction = bulkNumberSelected ? 'Add' : 'Apply';
    saveButtonText = `${tagsAction} Tags`;
  }

  const createTagMutation = useCreateTagMutation();

  const {
    formState: {errors},
    handleSubmit,
    getValues,
    register,
    reset: resetForm,
  } = useForm<{tagName: string}>({
    defaultValues: {
      tagName: '',
    },
  });

  const handleNewTagSubmit = () => {
    if (createTagMutation.isPending) {
      return;
    }

    createTagMutation.mutate(getValues('tagName'), {
      onSuccess: (newTag) => {
        // Reset the form to the default state.
        resetForm();
        // Auto-select newly-added tag
        setLocalSelectedTags((prevState) => [...prevState, newTag._id]);
      },
    });
  };

  return (
    <>
      <Box fontWeight={500} fontSize={14} mb={6} color="kgray.300" mx="6">
        {subHeaderText}
      </Box>
      <Box as="form" onSubmit={handleSubmit(handleNewTagSubmit)} my={4} mx="6">
        <FormControl
          isDisabled={createTagMutation.isPending || accountActions.isPending}
        >
          <InputGroup as={Flex} direction="column" gap="2">
            <Input
              autoComplete="off"
              maxLength={30}
              placeholder="Create a new tag"
              {...register('tagName', {
                minLength: TagName.minLength,
                maxLength: TagName.maxLength,
                pattern: TagName.pattern,
                required: true,
                validate: (value) =>
                  // Check the whitespace-trimmed tag name length
                  value.trim().length >= TagName.minLength,
              })}
            />
            <InputRightElement p={2}>
              {createTagMutation.isPending ? (
                <Spinner color="kblue.200" />
              ) : (
                <Button type="submit" p={0} size="sm">
                  <ChakraAddFilled
                    color={'kblue.300'}
                    height={'80%'}
                    width={'80%'}
                  />
                </Button>
              )}
            </InputRightElement>
            {errors.tagName && (
              <Box color="red.500" fontSize="sm" ml={2}>
                Tag names should be between {TagName.minLength} and{' '}
                {TagName.maxLength} characters, and contain only letters and
                numbers.
              </Box>
            )}
          </InputGroup>
        </FormControl>
      </Box>

      <Flex direction="column" maxH="30vh" minH="250px" overflowY="auto" px="6">
        {tags.isPending ? (
          <Spinner m="auto" />
        ) : (
          tags.data?.map((tag) => {
            return (
              <Checkbox
                key={tag._id.toString()}
                my={2}
                mx={1}
                onChange={(e) => onCheckboxClicked(tag, e.target.checked)}
                isChecked={localSelectedTags.some((id) => id.equals(tag._id))}
              >
                {tag.name}
              </Checkbox>
            );
          })
        )}
      </Flex>

      <Divider />

      <Flex m="6" mt="4" justifyContent="space-between">
        <Button
          colorScheme="kbuttonblue"
          isDisabled={isSaveButtonDisabled}
          isLoading={accountActions.isPending}
          onClick={() => updateAccount('tag')}
        >
          {saveButtonText}
        </Button>
        {!tagOnly && (
          <Button
            color="kgray.300"
            isLoading={accountActions.isPending}
            onClick={() => updateAccount('save')}
            variant="ghost"
          >
            Save without tagging
          </Button>
        )}
      </Flex>
    </>
  );
};

const ChakraAddFilled = chakra(AddFilled);
