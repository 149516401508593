import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Text,
  useModalContext,
  useToast,
} from '@chakra-ui/react';
import {useSelectedVersionStore} from './SelectedVersion.state';
import {useBuilderMode} from './BuilderModeContext';
import {useAiFieldBuilderStore} from './AiFieldBuilder.state';
import {useAiFieldBuilderNavStore} from './AiFieldBuilderNav.state';
import {WrapperFlex, ScrollableFlex} from '../../utils/scrolling';
import {FieldBuilderPageIndicator} from './FieldBuilderPageIndicator';
import Select from 'react-select';
import _ from 'lodash';

export const AiFieldBuilderReviewSettingsStep = () => {
  const {publishChanges} = useAiFieldBuilderStore();
  const {prevStep} = useAiFieldBuilderNavStore();
  const {selectedVersionId} = useSelectedVersionStore();
  const mode = useBuilderMode();
  const {onClose} = useModalContext();
  const toast = useToast();

  return (
    <Flex direction="column" flex="1">
      <Box position="relative" h="100%">
        <WrapperFlex>
          <ScrollableFlex alignItems="center" px="6">
            <Flex direction="column" maxW="800px" w="100%" flex="1">
              <FieldBuilderPageIndicator />
              <Flex direction="column" gap="8">
                <Text flexGrow="1" fontSize="xl">
                  Review Enrichment Settings
                </Text>
                <AutoRefreshSettings />
              </Flex>
            </Flex>
          </ScrollableFlex>
        </WrapperFlex>
      </Box>

      <Divider />

      <Flex justifyContent="space-between" w="100%" px={10} py={4}>
        <Button variant="ghost" onClick={prevStep}>
          Back
        </Button>

        <Button
          colorScheme="kbuttonblue"
          isLoading={publishChanges.isPending}
          onClick={() => {
            publishChanges.mutate(
              mode === 'review'
                ? {mode: 'save'}
                : {mode: 'publish', publishVersionId: selectedVersionId},
              {
                onSuccess: (_data, {mode}) => {
                  onClose();
                  if (mode === 'publish') {
                    toast({
                      title: 'Field definition published',
                      description:
                        'We will send an email when it has finished.',
                      status: 'success',
                    });
                  }
                },
              }
            );
          }}
        >
          {mode === 'review' ? 'Save & Exit' : 'Publish Field & Run Enrichment'}
        </Button>
      </Flex>
    </Flex>
  );
};

const RefreshOptionContainer = ({
  children,
  isSelected,
  title,
  onClick,
}: React.PropsWithChildren<{
  title: string;
  isSelected?: boolean;
  onClick?: () => void;
}>) => {
  return (
    <Flex
      direction="column"
      gap="4"
      px="6"
      py="4"
      bgColor={isSelected ? 'kblue.50' : 'white'}
      border="1px solid"
      borderColor={isSelected ? 'kblue.500' : 'kgray.200'}
      borderRadius="lg"
      cursor="pointer"
      onClick={(e) => {
        // Prevent an issue causing the click event to be fired twice
        e.preventDefault();
        onClick?.();
      }}
      _hover={{
        bgColor: !isSelected && 'kgray.100',
      }}
    >
      <Flex alignItems="center" gap="4">
        <Checkbox isChecked={isSelected} />
        <Text>{title}</Text>
      </Flex>
      {children}
    </Flex>
  );
};

const AutoEnrichNewAccountsOption = () => {
  const {autoEnrichNewAccounts, setAutoEnrichNewAccounts} =
    useAiFieldBuilderStore();
  return (
    <RefreshOptionContainer
      title="Automatically run on new accounts"
      isSelected={autoEnrichNewAccounts}
      onClick={() => setAutoEnrichNewAccounts(!autoEnrichNewAccounts)}
    />
  );
};

const AutoEnrichScheduleOptions = [
  {label: 'Every month', value: 'oneMonth'},
  {label: 'Every 3 months', value: 'threeMonths'},
  {label: 'Every 6 months', value: 'sixMonths'},
  {label: 'Every year', value: 'oneYear'},
] as const;

const AutoEnrichScheduleOption = () => {
  const {autoEnrichSchedule, setAutoEnrichSchedule} = useAiFieldBuilderStore();
  const isSelected = !!autoEnrichSchedule;

  return (
    <RefreshOptionContainer
      title="Auto-refresh on a schedule"
      isSelected={isSelected}
      onClick={() => {
        setAutoEnrichSchedule(
          !isSelected
            ? {
                schedule: 'oneMonth',
              }
            : undefined
        );
      }}
    >
      {isSelected && (
        <Box onClick={(e) => e.stopPropagation()}>
          <Select
            isMulti={false}
            options={AutoEnrichScheduleOptions}
            value={AutoEnrichScheduleOptions.find((option) =>
              _.isEqual(option.value, autoEnrichSchedule?.schedule)
            )}
            onChange={(option) => {
              if (!option) {
                return;
              }

              setAutoEnrichSchedule({
                schedule: option.value,
              });
            }}
          />
        </Box>
      )}
    </RefreshOptionContainer>
  );
};

const AutoRefreshSettings = () => {
  return (
    <Flex direction="column" gap="6">
      <Flex direction="column" gap="2">
        <Box fontSize="lg">Auto-refresh</Box>
        <Box color="kgray.300">
          Disable or enable automatic runs of this enrichment.
        </Box>
      </Flex>
      <AutoEnrichNewAccountsOption />
      <AutoEnrichScheduleOption />
    </Flex>
  );
};
