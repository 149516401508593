import React from 'react';
import {useGetTestLists} from '../../../hooks/api/testList';
import {
  Box,
  Flex,
  Skeleton,
  Stack,
  Table,
  Th,
  Tr,
  Thead,
  TableContainer,
  Td,
  Tbody,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import {pluralize} from '../../../lib/helpers';
import {WithId} from '../../../shared/util';
import {TestList} from '../../../shared/testLists';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
} from '@tanstack/react-table';
import {format} from 'date-fns';
import {EmptyTestListsContainer} from './EmptyTestListsContainer';
import {TestListLabel} from './TestListLabel';

export const TestLists = () => {
  const {data, isError, isPending} = useGetTestLists({});

  return (
    <Flex direction="column" gap="4">
      <Flex direction="column">
        <Box fontWeight="500">Test Lists</Box>
        <Box textColor="kgray.300" fontSize="sm">
          {pluralize(data?.length ?? 0, 'list', 'lists')}
        </Box>
      </Flex>
      {isPending ? (
        <Stack gap="4">
          <Skeleton height="40px" />
          <Skeleton height="40px" />
          <Skeleton height="40px" />
        </Stack>
      ) : isError ? (
        <Alert status="error">
          <AlertIcon />
          Error loading test lists. Please reload the page or contact us for
          help.
        </Alert>
      ) : !data.length ? (
        <EmptyTestListsContainer />
      ) : (
        <TestListsTable testLists={data} />
      )}
    </Flex>
  );
};

const columnHelper = createColumnHelper<WithId<TestList>>();
const columns = [
  columnHelper.accessor((testList) => testList, {
    header: 'List Name',
    cell: (cell) => <TestListLabel testList={cell.getValue()} />,
  }),
  columnHelper.accessor('status', {
    header: () => <Flex justifyContent="center">Accounts</Flex>,
    cell: (cell) => {
      const status = cell.getValue();
      return (
        <Flex justifyContent="center">
          {status.type === 'processing' ? (
            <Box
              bg="kblue.50"
              borderRadius="lg"
              color="kblue.500"
              fontSize="sm"
              px="2"
              py="1"
              textTransform="uppercase"
            >
              Processing
            </Box>
          ) : (
            status.numAccounts.toLocaleString()
          )}
        </Flex>
      );
    },
  }),
  columnHelper.accessor('timestamp', {
    header: () => <Flex justifyContent="center">Added</Flex>,
    cell: (cell) => {
      const timestamp = cell.getValue();
      return (
        <Flex justifyContent="center">{format(timestamp, 'M/dd/yy')}</Flex>
      );
    },
  }),
];

const TestListsTable = ({testLists}: {testLists: WithId<TestList>[]}) => {
  const table = useReactTable({
    columns,
    data: testLists,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 0,
      size: 150,
    },
  });

  return (
    <TableContainer border="1px" borderColor="kgray.200" borderRadius="md">
      <Table variant="simple">
        <Thead bg="kgray.100">
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th
                  key={header.id}
                  w={header.getSize() ? `${header.getSize()}px` : 'auto'}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr key={row.id} role="group">
              {row.getVisibleCells().map((cell) => (
                <Td
                  key={cell.id}
                  _groupHover={{bg: 'kgray.100'}}
                  w={
                    cell.getContext().column.getSize()
                      ? `${cell.getContext().column.getSize()}px`
                      : 'auto'
                  }
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
