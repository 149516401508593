import React, {useEffect, useState} from 'react';
import {HeaderButton, HeaderButtonProps} from './HeaderButton';
import {DataEnrichment} from '@carbon/icons-react';
import {
  Button,
  ButtonProps,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {useCustomer} from '../../../hooks/api/metadata';
import Select from 'react-select';
import {FieldDefinition} from '../../../shared/enrichment';
import {
  useNumAccountsToEnrich,
  useRunEnrichment,
} from '../../../hooks/api/enrichment';
import {useAccountQueryParams} from '../AccountGrid.controlstate';
import {useScoringRun} from '../../../context/ScoringRunContext';
import {ObjectId} from 'bson';

export const EnrichButton = ({...props}: HeaderButtonProps) => {
  const {isOpen, onClose, onOpen} = useDisclosure();

  return (
    <>
      <HeaderButton onClick={onOpen} leftIcon={<DataEnrichment />} {...props}>
        Enrich
      </HeaderButton>
      <EnrichAccountsModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

const EnrichAccountsModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Enrich Accounts</ModalHeader>
        <ModalCloseButton />
        <EnrichAccountsModalContent onClose={onClose} />
      </ModalContent>
    </Modal>
  );
};

const EnrichAccountsModalContent = ({onClose}: {onClose: () => void}) => {
  const customer = useCustomer();
  const options =
    customer.fieldDefinitions
      ?.filter(({type}) => type === 'ai' || type === 'roleCount')
      ?.filter(({status}) => status === 'published')
      ?.map((field) => ({
        value: field.id,
        label: field.label,
        field,
      })) ?? [];

  const [selectedField, setSelectedField] = useState<FieldDefinition | null>(
    null
  );
  const [overrideFieldValues, setOverrideFieldValues] = useState(false);

  return (
    <>
      <ModalBody as={Flex} flexDir="column" gap="8" mb="4">
        <FormControl>
          <FormLabel>Custom Field</FormLabel>
          <Select
            isMulti={false}
            onChange={(selected) => {
              if (!selected) {
                return;
              }

              setSelectedField(selected.field);
            }}
            options={options}
            placeholder="Select a field"
            value={options.find(
              (option) =>
                option.value.toString() === selectedField?.id.toString()
            )}
          />
        </FormControl>
        <FormControl>
          <Checkbox
            isChecked={overrideFieldValues}
            onChange={(e) => setOverrideFieldValues(e.target.checked)}
          >
            Re-run enrichment for all accounts in view?
          </Checkbox>
          <FormHelperText>
            By default, only accounts with no existing value will be enriched.
          </FormHelperText>
        </FormControl>
      </ModalBody>
      <Divider />
      <ModalFooter as={Flex} justifyContent="space-between" h="72px">
        <Text>Remaining credits: {customer.credits.toLocaleString()}</Text>
        {!selectedField ? null : (
          <EnrichAccountsButton
            colorScheme="kbuttonblue"
            fontWeight="normal"
            fieldDefinitionId={selectedField.id}
            onClose={onClose}
            overrideFieldValues={overrideFieldValues}
          />
        )}
      </ModalFooter>
    </>
  );
};

const EnrichAccountsButton = ({
  fieldDefinitionId,
  onClose,
  overrideFieldValues,
  ...props
}: {
  fieldDefinitionId: ObjectId;
  onClose: () => void;
  overrideFieldValues: boolean;
} & ButtonProps) => {
  const scoringRun = useScoringRun();
  const customer = useCustomer();
  const queryParams = useAccountQueryParams();
  const numAccountsToEnrich = useNumAccountsToEnrich();
  const runEnrichment = useRunEnrichment();
  const toast = useToast();

  const remainingCredits =
    customer.credits - (numAccountsToEnrich.data?.creditsNeeded ?? 0);
  const insufficientCredits = remainingCredits < 0;

  useEffect(() => {
    numAccountsToEnrich.mutate({
      accountSelection: {
        name: 'query',
        query: {
          ...queryParams,
          runId: scoringRun._id,
        },
      },
      fieldDefinitionId,
      overrideFieldValues,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldDefinitionId, overrideFieldValues, scoringRun]);

  return (
    <Tooltip label="Insufficient credits" isDisabled={!insufficientCredits}>
      <Button
        {...props}
        colorScheme="kbuttonblue"
        fontWeight="normal"
        isDisabled={
          !numAccountsToEnrich.data?.numAccounts ||
          insufficientCredits ||
          !scoringRun
        }
        isLoading={numAccountsToEnrich.isPending || runEnrichment.isPending}
        onClick={() => {
          if (!numAccountsToEnrich.data || !scoringRun) {
            return;
          }

          runEnrichment.mutate(
            {
              accountSelection: {
                name: 'query',
                query: {
                  ...queryParams,
                  runId: scoringRun._id,
                },
              },
              fieldDefinitionId,
              overrideFieldValues,
            },
            {
              onSuccess: () => {
                toast({
                  title: 'Enrichment task started',
                  description: 'We will send an email when it has finished.',
                  status: 'success',
                });
                onClose();
              },
            }
          );
        }}
      >
        {numAccountsToEnrich.data
          ? `Enrich ${numAccountsToEnrich.data.numAccounts.toLocaleString()} Accounts`
          : 'Enrich Accounts'}
      </Button>
    </Tooltip>
  );
};
