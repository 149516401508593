import React from 'react';
import {
  Box,
  Button,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  UnorderedList,
} from '@chakra-ui/react';
import {useDeleteFieldDefinition} from '../../hooks/api/fieldDefinitions';
import {useCustomer} from '../../hooks/api/metadata';
import {FieldDefinition} from '../../shared/enrichment';
import {getSignalEvalFnInputs} from '../../shared/signalDefinition';

export const DeleteFieldModal = ({
  fieldDefinition,
  isOpen,
  onClose,
}: {
  fieldDefinition: FieldDefinition;
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <DeleteFieldModalContent
        fieldDefinition={fieldDefinition}
        onClose={onClose}
      />
    </Modal>
  );
};

const DeleteFieldModalContent = ({
  fieldDefinition,
  onClose,
}: {
  fieldDefinition: FieldDefinition;
  onClose: () => void;
}) => {
  const {id: fieldId, label: fieldLabel} = fieldDefinition;
  const {signalDefinitions} = useCustomer();
  const referenedSignals = (signalDefinitions ?? []).filter(({evalFn}) => {
    const inputs = getSignalEvalFnInputs(evalFn);
    return inputs.some(
      (input) => input.type === 'field' && fieldId.equals(input.field)
    );
  });

  const deleteFieldDefinition = useDeleteFieldDefinition();

  return (
    <ModalContent>
      <ModalCloseButton />
      {referenedSignals.length ? (
        <>
          <ModalHeader>Unable to Delete &apos;{fieldLabel}&apos;</ModalHeader>
          <ModalBody>
            <Box>
              This field is currently being used in the following signals:
            </Box>
            <UnorderedList ps="4" pt="4">
              {referenedSignals.map((signal) => (
                <ListItem key={signal.id}>{signal.label}</ListItem>
              ))}
            </UnorderedList>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>OK</Button>
          </ModalFooter>
        </>
      ) : (
        <>
          <ModalHeader>Delete &apos;{fieldLabel}&apos;?</ModalHeader>
          <ModalBody>
            Deleting this field will remove all data associated with it.
          </ModalBody>
          <ModalFooter gap="4">
            <Button onClick={onClose}>Cancel</Button>
            <Button
              colorScheme="red"
              isLoading={deleteFieldDefinition.isPending}
              onClick={() =>
                deleteFieldDefinition.mutate({fieldId}, {onSuccess: onClose})
              }
            >
              Delete
            </Button>
          </ModalFooter>
        </>
      )}
    </ModalContent>
  );
};
