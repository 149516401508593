import React, {useState} from 'react';
import {useOnboardingContext} from './Onboarding';
import {
  Box,
  Flex,
  Image,
  Button,
  Checkbox,
  Link,
  Text,
  Tooltip,
} from '@chakra-ui/react';

export const GetStarted = () => {
  const {onPageNavigate} = useOnboardingContext();
  const [agreedToTerms, setAgreedToTerms] = useState(true);

  return (
    <Flex
      alignItems={'center'}
      direction={'column'}
      h="100%"
      w="100%"
      gap={5}
      py={5}
      textAlign={'center'}
    >
      <Box fontSize="2xl">Start building your ICP with Keyplay</Box>
      <Image
        flexGrow={1}
        flexBasis={0}
        maxH={'475px'}
        minH={'300px'}
        objectFit="contain"
        src="/Keyplay_Ecosystem.png"
      />
      <Checkbox
        isChecked={agreedToTerms}
        onChange={(e) => setAgreedToTerms(e.target.checked)}
      >
        <Text fontSize="xs" color="kgray.300">
          I agree to Keyplay&apos;s{' '}
          <Link
            href="https://keyplay.io/legal/terms"
            isExternal
            color="kblue.400"
          >
            terms & conditions.
          </Link>
        </Text>
      </Checkbox>
      <Tooltip
        hasArrow
        isDisabled={agreedToTerms}
        label="Please agree to the terms & conditions to continue"
      >
        <Button
          colorScheme="kbuttonblue"
          onClick={() => onPageNavigate('next')}
          variant="solid"
          width="500px"
          isDisabled={!agreedToTerms}
        >
          Get Started
        </Button>
      </Tooltip>
    </Flex>
  );
};
