import React from 'react';
import {useSignalBuilderStore} from './SignalBuilder.state';
import {Box, Flex, GridItem} from '@chakra-ui/react';
import {LabelInput} from './helpers';
import Select from 'react-select';
import {useResolvedField} from '../../../hooks/fields';
import {AccountFieldOrCustomField} from '../../../shared/signalDefinition';

type BooleanEvalType = 'isTrue' | 'isFalse';

const booleanOptions = [
  {value: 'isTrue', label: 'Is True'},
  {value: 'isFalse', label: 'Is False'},
] as const;

export const BooleanSignalBuilder = ({
  field,
}: {
  field: AccountFieldOrCustomField;
}) => {
  const {label: fieldLabel} = useResolvedField(field);
  const {evalFn, setEvalFn, setLabel} = useSignalBuilderStore();

  if (evalFn && evalFn.type !== 'isTrue' && evalFn.type !== 'isFalse') {
    throw new Error('Invalid evalFn type');
  }

  const update = (type: BooleanEvalType) => {
    setEvalFn({
      type,
      field,
    });
    setLabel(type === 'isTrue' ? `Has ${fieldLabel}` : `No ${fieldLabel}`);
  };

  return (
    <>
      <GridItem as={Flex} alignItems="center" flexDirection="row" gap="4">
        <Box w="100%">
          <Select
            isMulti={false}
            options={booleanOptions}
            value={booleanOptions.find(
              (option) => option.value === evalFn?.type
            )}
            onChange={(newValue) => {
              if (!newValue) {
                return;
              }

              update(newValue.value);
            }}
          />
        </Box>
      </GridItem>
      <GridItem alignSelf="center">
        <LabelInput />
      </GridItem>
    </>
  );
};
