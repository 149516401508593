import {ObjectId} from 'bson';
import _ from 'lodash';
import {RoleSearchCountrySchema} from './countries';
import {z} from 'zod';
import {zodTypeguard} from './api/helpers';
import {zodEnum} from './util';
import {ObjectIdSchema} from './zod';

export const EnrichedFieldSchema = z.record(z.unknown());
export type EnrichedFields = z.infer<typeof EnrichedFieldSchema>;

export const FieldDataTypes = [
  'date',
  'dateTime',
  'number',
  'string',
  'boolean',
] as const;
const FieldDataTypeSchema = zodEnum(FieldDataTypes);
export type FieldDataType = z.infer<typeof FieldDataTypeSchema>;

const BaseFieldDefinitionSchema = z.object({
  id: z.instanceof(ObjectId),
  label: z.string(),
  dataType: FieldDataTypeSchema,
  timestamp: z.date(),
  status: z.union([z.literal('draft'), z.literal('published')]),

  // overridden by subtypes
  config: z.object({}),
  type: z.string(),
});

const AnalysisFieldSchema = z.union([
  //  account fields
  z.literal('businessAndMarket'),
  z.literal('companyDomain'),
  z.literal('companyName'),
  z.literal('historyAndCurrentState'),
  z.literal('homepageDescription'),
  z.literal('homepageTitle'),
  z.literal('personalityAndCulture'),

  // scoring data fields
  z.literal('employeeCount'),
  z.literal('followerCount'),
  z.literal('locationCountries'),
  z.literal('trafficRank'),
  z.literal('yearFounded'),

  // signals
  z.literal('orgSignals'),
  z.literal('profileSignals'),
  z.literal('relevanceSignals'),
  z.literal('stackSignals'),

  // page text fields
  z.literal('aboutPageText'),
  z.literal('careersPageText'),
  z.literal('contactPageText'),
  z.literal('jobBoardPageText'),
  z.literal('partnersPageText'),
  z.literal('pricingPageText'),
  z.literal('privacyPageText'),
  z.literal('resourcesPageText'),
  z.literal('rootPageText'),
  z.literal('securityPageText'),
  z.literal('supportPageText'),

  // page source fields
  z.literal('rootPageSource'),

  z.literal('dnsRecords'),
]);
export type AnalysisField = z.infer<typeof AnalysisFieldSchema>;
export const AnalysisFields = AnalysisFieldSchema.options.map(
  (option) => option.value
);
export const AnalysisFieldLabels = {
  businessAndMarket: 'Business and Market',
  companyDomain: 'Company Domain',
  companyName: 'Company Name',
  historyAndCurrentState: 'History and Current State',
  homepageDescription: 'Homepage Description',
  homepageTitle: 'Homepage Title',
  personalityAndCulture: 'Personality and Culture',

  employeeCount: 'Employee Count',
  followerCount: 'Follower Count',
  locationCountries: 'Location Countries',
  trafficRank: 'Traffic Rank',
  yearFounded: 'Year Founded',

  orgSignals: 'Org Signals',
  profileSignals: 'Profile Signals',
  relevanceSignals: 'Relevance Signals',
  stackSignals: 'Stack Signals',

  aboutPageText: 'About Page Text',
  careersPageText: 'Careers Page Text',
  contactPageText: 'Contact Page Text',
  jobBoardPageText: 'Job Board Page Text',
  partnersPageText: 'Partners Page Text',
  pricingPageText: 'Pricing Page Text',
  privacyPageText: 'Privacy Page Text',
  resourcesPageText: 'Resources Page Text',
  rootPageText: 'Root Page Text',
  securityPageText: 'Security Page Text',
  supportPageText: 'Support Page Text',

  rootPageSource: 'Root Page Source',

  dnsRecords: 'DNS Records',
} satisfies Record<AnalysisField, string>;

// Field definition configs
const RoleCountFieldDefinitionConfigSchema = z.object({
  include: z.array(z.string()),
  exclude: z.array(z.string()),
  titles: z.array(z.string()),
  countries: z.array(RoleSearchCountrySchema),
});
export type RoleCountFieldDefinitionConfig = z.infer<
  typeof RoleCountFieldDefinitionConfigSchema
>;

const CrmFieldDefinitionConfigSchema = z.object({
  fieldName: z.string(),
  integrationId: z.instanceof(ObjectId),
});

const AiFieldDefinitionConfigBaseSchema = z.object({
  analysisFields: AnalysisFieldSchema.array(),
  model: z.union([z.literal('gpt-4o'), z.literal('gpt-4o-mini')]),
  clarifications: z.string().array().optional(),
});

const AiBooleanFieldDefinitionConfigSchema =
  AiFieldDefinitionConfigBaseSchema.extend({
    fieldType: z.literal('boolean'),
    prompt: z.string(),
  });
export type AiBooleanFieldDefinitionConfig = z.infer<
  typeof AiBooleanFieldDefinitionConfigSchema
>;

const AiRatingFieldDefinitionConfigSchema =
  AiFieldDefinitionConfigBaseSchema.extend({
    fieldType: z.literal('rating'),
    prompt: z.string(),
    min: z.number(),
    max: z.number(),
  });
export type AiRatingFieldDefinitionConfig = z.infer<
  typeof AiRatingFieldDefinitionConfigSchema
>;

const AiCategoryFieldDefinitionConfigSchema =
  AiFieldDefinitionConfigBaseSchema.extend({
    fieldType: z.literal('category'),
    categoryName: z.string(),
    categories: z
      .object({
        name: z.string(),
        definition: z.string(),
        examples: z.string().array(),
      })
      .array(),
  });
export type AiCategoryFieldDefinitionConfig = z.infer<
  typeof AiCategoryFieldDefinitionConfigSchema
>;

// Note: keep in sync with different field type schemas
export const AiFieldDefinitionConfigSchema = z.union([
  AiBooleanFieldDefinitionConfigSchema,
  AiRatingFieldDefinitionConfigSchema,
  AiCategoryFieldDefinitionConfigSchema,
]);
export type AiFieldDefinitionConfig = z.infer<
  typeof AiFieldDefinitionConfigSchema
>;

export const FieldDefinitionConfigSchema = z.union([
  RoleCountFieldDefinitionConfigSchema,
  CrmFieldDefinitionConfigSchema,
  AiFieldDefinitionConfigSchema,
]);
type FieldDefinitionConfig = z.infer<typeof FieldDefinitionConfigSchema>;

const MyAccountsFilterSchema = z
  .object({
    active: z.literal(true),
    excluded: z.literal(false),
  })
  .strict();
export const isMyAccountsFilter = zodTypeguard(MyAccountsFilterSchema);

export const TaggedAccountsFilterSchema = MyAccountsFilterSchema.extend({
  tags: ObjectIdSchema.array().length(1),
}).strict();
export const isTaggedAccountsFilter = zodTypeguard(TaggedAccountsFilterSchema);

const AiEnrichmentAccountFilterSchema = z.union([
  MyAccountsFilterSchema,
  TaggedAccountsFilterSchema,
]);
export type AiEnrichmentAccountFilter = z.infer<
  typeof AiEnrichmentAccountFilterSchema
>;
export const isAiEnrichmentAccountFilter = zodTypeguard(
  AiEnrichmentAccountFilterSchema
);

const AutoEnrichScheduleSchema = z.object({
  lastRun: z.date().optional(),
  schedule: z.union([
    z.literal('oneMonth'),
    z.literal('threeMonths'),
    z.literal('sixMonths'),
    z.literal('oneYear'),
  ]),
});
export type AutoEnrichSchedule = z.infer<typeof AutoEnrichScheduleSchema>;

const EnrichmentRefreshSettingsSchema = z.object({
  accountFilter: AiEnrichmentAccountFilterSchema,
  autoEnrichNewAccounts: z.boolean().optional(),
  autoEnrichSchedule: AutoEnrichScheduleSchema.optional(),
});
export type EnrichmentRefreshSettings = z.infer<
  typeof EnrichmentRefreshSettingsSchema
>;

function fieldBuilderEnabledSchema<T extends z.ZodType<FieldDefinitionConfig>>(
  configSchema: T
) {
  return z.object({
    config: configSchema,
    preview: z
      .object({
        accounts: ObjectIdSchema.array(),
        versions: z
          .object({
            id: ObjectIdSchema,
            label: z.string(),
            config: configSchema,
            timestamp: z.date(),
          })
          .array(),
      })
      .optional(),
    publishedVersionId: ObjectIdSchema.optional(),
    refreshSettings: EnrichmentRefreshSettingsSchema.optional(),
  });
}

// Field definitions

const RoleCountFieldDefinitionSchema = BaseFieldDefinitionSchema.extend({
  type: z.literal('roleCount'),
  config: RoleCountFieldDefinitionConfigSchema,
});
export type RoleCountFieldDefinition = z.infer<
  typeof RoleCountFieldDefinitionSchema
>;

export const CrmFieldDefinitionSchema = BaseFieldDefinitionSchema.extend({
  type: z.literal('crm'),
  config: CrmFieldDefinitionConfigSchema,
});
export type CrmFieldDefinition = z.infer<typeof CrmFieldDefinitionSchema>;
export const isCrmFieldDefinition = zodTypeguard(CrmFieldDefinitionSchema);

const AiFieldDefinitionBaseSchema = (dataType: FieldDataType) =>
  BaseFieldDefinitionSchema.extend({
    type: z.literal('ai'),
    dataType: z.literal(dataType),
  });

export const AiBooleanFieldDefinitionSchema = AiFieldDefinitionBaseSchema(
  'boolean'
).merge(fieldBuilderEnabledSchema(AiBooleanFieldDefinitionConfigSchema));

export const AiRatingFieldDefinitionSchema = AiFieldDefinitionBaseSchema(
  'number'
).merge(fieldBuilderEnabledSchema(AiRatingFieldDefinitionConfigSchema));

export const AiCategoryFieldDefinitionSchema = AiFieldDefinitionBaseSchema(
  'string'
).merge(fieldBuilderEnabledSchema(AiCategoryFieldDefinitionConfigSchema));

export const AiFieldDefinitionSchema = z.union([
  AiBooleanFieldDefinitionSchema,
  AiRatingFieldDefinitionSchema,
  AiCategoryFieldDefinitionSchema,
]);

export type AiFieldDefinition = z.infer<typeof AiFieldDefinitionSchema>;
export type AiFieldType = AiFieldDefinition['config']['fieldType'];

const AiFieldDataTypeSchema = z.union([z.boolean(), z.number(), z.string()]);
export type AiFieldDataType = z.infer<typeof AiFieldDataTypeSchema>;

export const FieldDefinitionSchema = z.union([
  RoleCountFieldDefinitionSchema,
  CrmFieldDefinitionSchema,
  AiFieldDefinitionSchema,
]);
export const isFieldDefinition = zodTypeguard(FieldDefinitionSchema);

export type FieldDefinition = z.infer<typeof FieldDefinitionSchema>;

export const FieldDefinitionTypeLabels: Record<
  FieldDefinition['type'],
  string
> = {
  ai: 'AI Field',
  crm: 'CRM Field',
  roleCount: 'Role Count',
};

export const FieldDefinitionStatusLabels: Record<
  FieldDefinition['status'],
  string
> = {
  draft: 'Draft',
  published: 'Published',
};

export interface CustomEnrichmentRun {
  customerId: ObjectId;
  fieldDefinition: FieldDefinition;
  expectedNumberOfEnrichedAccounts: number;
  actualNumberOfEnrichedAccounts: number;
  timestamp: Date;
}

// HACK: we don't currently have a universal concept of a parent EnrichmentFieldAction.
// So in the meantime we just use this as a way to group enrichment values that were
// generated together. https://github.com/keyplay-io/bfdb/pull/1572 has some discussion
// and prototyping around what we might want to implement in the future
export type EnrichmentSource =
  | {type: 'enrichmentRun'; id: ObjectId}
  | {type: 'api'; id: ObjectId};

export interface FieldMetadata {
  reasoning: string;
}

export interface EnrichedFieldValue {
  customerId: ObjectId;
  accountId: ObjectId;
  fieldDefinitionId: ObjectId;

  source: EnrichmentSource;

  timestamp: Date;
  value: unknown;
  metadata?: FieldMetadata;
}

export interface FieldDefinitionAnalysisRunResult {
  customerId: ObjectId;
  accountId: ObjectId;
  analysisRunId: ObjectId;
  testListIds: ObjectId[];
  timestamp: Date;

  value: unknown;
  metadata?: FieldMetadata;
}

const TestListResultsBaseSchema = z.object({
  testListId: ObjectIdSchema,
  status: z.string(),
});

const RunningTestListResultsSchema = TestListResultsBaseSchema.extend({
  status: z.literal('running'),
});

const SuccessTestListResultsSchema = TestListResultsBaseSchema.extend({
  status: z.literal('success'),
  valueCounts: z.array(
    z.object({
      value: AiFieldDataTypeSchema,
      count: z.number(),
    })
  ),
});

export const TestListResultsSchema = z.union([
  RunningTestListResultsSchema,
  SuccessTestListResultsSchema,
]);
export type TestListResults = z.infer<typeof TestListResultsSchema>;

export const FieldDefinitionAnalysisRunSchema = z.object({
  customerId: ObjectIdSchema,
  fieldDefinitionId: ObjectIdSchema,
  config: AiFieldDefinitionConfigSchema,
  timestamp: z.date(),
  results: TestListResultsSchema.array(),
  versionId: ObjectIdSchema,
});
export type FieldDefinitionAnalysisRun = z.infer<
  typeof FieldDefinitionAnalysisRunSchema
>;

export const MaxFieldDefinitionAnalysisRunAccounts = 10_000;
export const MaxFieldDefinitionAnalysisRunTestLists = 10;
