import React, {useEffect, useState} from 'react';
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ModalBody,
  ModalFooter,
  Button,
  Flex,
  Text,
  Checkbox,
  useModalContext,
} from '@chakra-ui/react';
import {TestList} from '../../../shared/testLists';
import {WithId} from '../../../shared/util';
import {useGetTestLists} from '../../../hooks/api/testList';
import {ObjectId} from 'bson';
import {useSelectTestListStore} from './SelectTestListsModal.state';
import {TestListLabel} from './TestListLabel';

export const SelectTestListsModal = ({
  isOpen,
  onClose,
  onSelectTestLists,
  selectedTestLists,
  selectButtonText,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSelectTestLists: (testLists: ObjectId[]) => void;
  selectedTestLists: ObjectId[];
  selectButtonText?: string;
}) => {
  const {setTestLists} = useSelectTestListStore();
  const resetState = () => {
    setTestLists([]);
  };

  return (
    <Modal
      isCentered={true}
      isOpen={isOpen}
      onClose={onClose}
      onCloseComplete={resetState}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select Test Lists</ModalHeader>

        <SelectTestListModalContent
          selectButtonText={selectButtonText}
          selectedTestLists={selectedTestLists}
          onSelectTestLists={onSelectTestLists}
        />
      </ModalContent>
    </Modal>
  );
};

const SelectTestListModalContent = ({
  onSelectTestLists,
  selectedTestLists,
  selectButtonText,
}: {
  onSelectTestLists: (testLists: ObjectId[]) => void;
  selectedTestLists: ObjectId[];
  selectButtonText?: string;
}) => {
  const {data, isPending} = useGetTestLists({});
  const {testLists, setTestLists} = useSelectTestListStore();
  const {onClose} = useModalContext();

  const [hasInitialized, setHasInitialized] = useState(false);
  useEffect(() => {
    // Don't override the selected test lists once set
    if (!hasInitialized) {
      setTestLists(selectedTestLists);
      setHasInitialized(true);
    }
  }, [selectedTestLists, setTestLists, hasInitialized]);

  return (
    <>
      <ModalBody>
        <TestListTable testLists={data ?? []} />
      </ModalBody>
      <ModalFooter as={Flex} justifyContent="space-between">
        <Button onClick={onClose} variant="ghost">
          Cancel
        </Button>
        <Button
          colorScheme="kbuttonblue"
          isDisabled={isPending || !testLists.length}
          onClick={() => {
            onSelectTestLists(testLists);
            onClose();
          }}
        >
          {selectButtonText ?? 'Select'}
        </Button>
      </ModalFooter>
    </>
  );
};

const TestListRow = ({testList}: {testList: WithId<TestList>}) => {
  const {testLists, selectTestList, deselectTestList} =
    useSelectTestListStore();
  const isSelected = testLists.some((id) => id.equals(testList._id));

  return (
    <Flex
      alignItems="center"
      border="1px solid"
      borderColor="kgray.200"
      borderRadius="md"
      cursor="pointer"
      justifyContent="space-between"
      onClick={(e) => {
        // Prevent the checkbox from receiving the click
        e.preventDefault();
        isSelected
          ? deselectTestList(testList._id)
          : selectTestList(testList._id);
      }}
      p="4"
      _hover={{
        bg: 'kgray.100',
      }}
    >
      <Flex gap="4">
        <Checkbox isChecked={isSelected} size="lg" />
        <TestListLabel testList={testList} />
      </Flex>
      <Text color="kgray.300">
        {testList.status.type === 'processing'
          ? 'Processing'
          : `${testList.status.numAccounts.toLocaleString()} accounts`}
      </Text>
    </Flex>
  );
};

const TestListTable = ({testLists}: {testLists: WithId<TestList>[]}) => {
  return (
    <Flex direction="column" gap="2">
      {testLists.map((testList) => (
        <TestListRow key={testList._id.toString()} testList={testList} />
      ))}
    </Flex>
  );
};
