import {z} from 'zod';
import {CountryCodeSchema} from './countries';
import {StrictExclude, keys} from './util';
import {zodTypeguard} from './api/helpers';
import {FieldDataType, FieldDefinition} from './enrichment';
import {AccountFieldOrCustomField} from './signalDefinition';

const AccountFieldTypesSchema = z.object({
  finalDomain: z.string(),
  finalLinkedIn: z.string(),

  ats: z.string(),
  jobBoardURL: z.string(),
  keyplayJobBoardUrl: z.string(),
  openRolePercentage: z.number(),
  recruitingVelocity: z.number(),
  trafficRank: z.number(),

  employeeCount: z.number(),
  followerCount: z.number(),
  hqCity: z.string(),
  hqCountry: CountryCodeSchema,
  hqPostalCode: z.string(),
  hqState: z.string(),
  linkedInJobCount: z.number(),
  locationCities: z.array(z.string()),
  locationCount: z.number(),
  locationCountries: z.array(CountryCodeSchema),
  locationStates: z.array(z.string()),
  name: z.string(),
  stockTicker: z.string(),
  tagline: z.string(),
  yearFounded: z.number(),

  blogs: z.number(),
  events: z.number(),
  integrations: z.number(),
  platform: z.number(),
  products: z.number(),
  solutions: z.number(),
  verticals: z.number(),
  webinars: z.number(),

  // Hiring
  hiring: z.number(),
  hiringABM: z.number(),
  hiringAEorAM: z.number(),
  hiringAi: z.number(),
  hiringBrandOrComms: z.number(),
  hiringCommunity: z.number(),
  hiringContentMarketing: z.number(),
  hiringCustomerSuccess: z.number(),
  hiringDataTeam: z.number(),
  hiringDemandGen: z.number(),
  hiringDesign: z.number(),
  hiringEng: z.number(),
  hiringFinance: z.number(),
  hiringGrowth: z.number(),
  hiringHR: z.number(),
  hiringMarketing: z.number(),
  hiringMarketingOps: z.number(),
  hiringOtherOps: z.number(),
  hiringProduct: z.number(),
  hiringProductMarketing: z.number(),
  hiringRevOrSalesOps: z.number(),
  hiringSDRorBDR: z.number(),
  hiringSales: z.number(),
  hiringSalesEnablement: z.number(),
  hiringSalesOrSolutionsEng: z.number(),
  hiringSoftwareEng: z.number(),
  hiringSpecialists: z.number(),
  hiringSupport: z.number(),

  openContractorRoles: z.number(),

  // URLs
  pricingPage: z.string(),
  productPage: z.string(),
  demoPage: z.string(),
  signupPage: z.string(),
  partnersPage: z.string(),
  slackCommunityPage: z.string(),

  // enhanced LinkedIn fields
  sixMonthGrowth: z.number(),
  oneYearGrowth: z.number(),
  lastFundingDate: z.string(),
  totalFundingRaised: z.number(),
  linkedInLogoUrl: z.string(),

  // month-over-month changes
  oneMonthFollowerChange: z.number(),
  sixMonthFollowerChange: z.number(),
  oneMonthHeadcountGrowth: z.number(),
  oneMonthOpenRolesChange: z.number(),
  oneMonthRecruitingVelocityChange: z.number(),
  oneMonthTotalFundingChange: z.number(),
  oneMonthHiringCustomerSuccessChange: z.number(),
  oneMonthHiringEngChange: z.number(),
  oneMonthHiringFinanceChange: z.number(),
  oneMonthHiringHRChange: z.number(),
  oneMonthHiringMarketingChange: z.number(),
  oneMonthHiringProductChange: z.number(),
  oneMonthHiringSalesChange: z.number(),
});

export type AccountFieldTypes = z.infer<typeof AccountFieldTypesSchema>;

export const AccountFieldSchema = AccountFieldTypesSchema.keyof();
export type AccountField = z.infer<typeof AccountFieldSchema>;
export const isAccountField = zodTypeguard(AccountFieldSchema);

export type NumericField = keyof {
  [K in keyof AccountFieldTypes as AccountFieldTypes[K] extends number
    ? K
    : never]: unknown;
};

export const AccountFieldLabels = {
  finalDomain: 'Final Domain',
  finalLinkedIn: 'Final LinkedIn',

  ats: 'ATS',
  jobBoardURL: 'Job Board URL',
  keyplayJobBoardUrl: 'Original Job Board URL',
  openRolePercentage: 'Open Roles / Total Headcount',
  recruitingVelocity: 'Recruiting Velocity Score',
  trafficRank: 'Traffic Rank',

  employeeCount: 'Employees',
  followerCount: 'Follower Count',
  hqCity: 'HQ City',
  hqCountry: 'HQ Country',
  hqPostalCode: 'HQ Postal Code',
  hqState: 'HQ State',
  linkedInJobCount: 'LinkedIn Job Count',
  locationCities: 'Location Cities',
  locationCount: 'Location Count',
  locationCountries: 'Location Countries',
  locationStates: 'Location States',
  name: 'Company Name',
  stockTicker: 'Stock Ticker',
  tagline: 'Tagline',
  yearFounded: 'Year Founded',

  blogs: 'Blogs',
  events: 'Events',
  integrations: 'Integrations',
  platform: 'Platform',
  products: 'Products',
  solutions: 'Solutions',
  verticals: 'Verticals',
  webinars: 'Webinars',

  hiring: 'Hiring',
  hiringABM: 'Hiring ABM',
  hiringAEorAM: 'Hiring AE/AM',
  hiringAi: 'Hiring AI',
  hiringBrandOrComms: 'Hiring Brand/Comms',
  hiringCommunity: 'Hiring Community',
  hiringContentMarketing: 'Hiring Content Marketing',
  hiringCustomerSuccess: 'Hiring Cust Success',
  hiringDataTeam: 'Hiring Data Team',
  hiringDemandGen: 'Hiring Demand Gen',
  hiringDesign: 'Hiring Design',
  hiringEng: 'Hiring Eng',
  hiringFinance: 'Hiring Finance',
  hiringGrowth: 'Hiring Growth',
  hiringHR: 'Hiring HR',
  hiringMarketing: 'Hiring Marketing',
  hiringMarketingOps: 'Hiring Marketing Ops',
  hiringOtherOps: 'Hiring Other Ops',
  hiringProduct: 'Hiring Product',
  hiringProductMarketing: 'Hiring Product Marketing',
  hiringRevOrSalesOps: 'Hiring Rev/Sales Ops',
  hiringSDRorBDR: 'Hiring SDR/BDR',
  hiringSales: 'Hiring Sales',
  hiringSalesEnablement: 'Hiring Sales Enablement',
  hiringSalesOrSolutionsEng: 'Hiring Sales/Solutions Eng',
  hiringSoftwareEng: 'Hiring Software Eng',
  hiringSpecialists: 'Hiring Specialists',
  hiringSupport: 'Hiring Support',

  openContractorRoles: 'Open Contractor Roles',

  demoPage: 'Demo Page',
  partnersPage: 'Partners Page',
  pricingPage: 'Pricing Page',
  productPage: 'Product Page',
  signupPage: 'Signup Page',
  slackCommunityPage: 'Slack Community Page',

  sixMonthGrowth: 'Headcount Growth (6 mo)',
  oneYearGrowth: 'Headcount Growth (Y/Y)',
  lastFundingDate: 'Last Funding Date',
  totalFundingRaised: 'Total Funding',
  linkedInLogoUrl: 'Logo Url',

  oneMonthFollowerChange: 'Follower Change (1 mo)',
  sixMonthFollowerChange: 'Follower Change (6 mo)',

  oneMonthHeadcountGrowth: 'Headcount Growth (1 mo)',
  oneMonthOpenRolesChange: 'M/M Open Roles Change',
  oneMonthRecruitingVelocityChange: 'M/M Recruiting Velocity Change',
  oneMonthTotalFundingChange: 'M/M Total Funding Change',
  oneMonthHiringCustomerSuccessChange: 'M/M Hiring Customer Success Change',
  oneMonthHiringEngChange: 'M/M Hiring Engineering Change',
  oneMonthHiringFinanceChange: 'M/M Hiring Finance Change',
  oneMonthHiringHRChange: 'M/M Hiring HR Change',
  oneMonthHiringMarketingChange: 'M/M Hiring Marketing Change',
  oneMonthHiringProductChange: 'M/M Hiring Product Change',
  oneMonthHiringSalesChange: 'M/M Hiring Sales Change',
} satisfies Record<AccountField, string>;

export const AccountFieldList = keys(AccountFieldLabels);

// This is used in few different places:
//  - we write these fields to ScoredAccount
//  - these fields are returned from our API
//
// TODO: we could probably pull this apart into different types
// for different use cases (this might also make it easier to write
// less data to ScoredAccount)
export const CoreAccountFieldList = [
  'finalDomain',
  'finalLinkedIn',

  'jobBoardURL',
  'openRolePercentage',
  'recruitingVelocity',
  'trafficRank',

  'employeeCount',
  'followerCount',
  'hqCity',
  'hqCountry',
  'hqPostalCode',
  'hqState',
  'locationCities',
  'locationCount',
  'locationCountries',
  'locationStates',
  'name',
  'stockTicker',
  'tagline',
  'yearFounded',

  'ats',
  'hiring',
  'hiringEng',
  'hiringSales',
  'hiringSoftwareEng',

  'linkedInLogoUrl',

  // change fields
  'oneMonthFollowerChange',
  'sixMonthFollowerChange',
  'oneMonthHeadcountGrowth',
  'oneMonthOpenRolesChange',
  'oneMonthRecruitingVelocityChange',
  'oneMonthTotalFundingChange',
  'oneMonthHiringCustomerSuccessChange',
  'oneMonthHiringEngChange',
  'oneMonthHiringFinanceChange',
  'oneMonthHiringHRChange',
  'oneMonthHiringMarketingChange',
  'oneMonthHiringProductChange',
  'oneMonthHiringSalesChange',

  // extended linkedin fields
  'sixMonthGrowth',
  'lastFundingDate',
  'totalFundingRaised',
] as const satisfies readonly AccountField[];

export const CoreAccountFieldSchema = z.enum(CoreAccountFieldList);
export type CoreAccountField = z.infer<typeof CoreAccountFieldSchema>;

export type OptionalAccountField = StrictExclude<
  AccountField,
  CoreAccountField
>;
export const isOptonalAccountField = (
  field: AccountField
): field is OptionalAccountField =>
  !CoreAccountFieldList.includes(field as CoreAccountField);

export type AccountFieldLabel =
  (typeof AccountFieldLabels)[keyof typeof AccountFieldLabels];

export const AccountFieldsSchema = AccountFieldTypesSchema.partial();
export type AccountFields = z.infer<typeof AccountFieldsSchema>;

export const CustomSignalFields = [
  'employeeCount',
  'followerCount',
  'hiring',
  'totalFundingRaised',
  'trafficRank',
  'yearFounded',
] as const satisfies readonly NumericField[];
const CustomSignalFieldSchema = z.enum(CustomSignalFields);
export type CustomSignalField = z.infer<typeof CustomSignalFieldSchema>;

export type ResolvedField = {
  id: AccountFieldOrCustomField;
  label: string;
  dataType: FieldDataType;
  source: 'custom' | 'keyplay';
};

export function resolveField(
  field: AccountFieldOrCustomField,
  fieldDefinitions: FieldDefinition[]
): ResolvedField | null {
  if (isAccountField(field)) {
    const fieldSchema = AccountFieldTypesSchema.shape[field];

    let dataType: FieldDataType | undefined;
    if (fieldSchema instanceof z.ZodString) {
      dataType = 'string';
    } else if (fieldSchema instanceof z.ZodNumber) {
      dataType = 'number';
    }

    // TODO: add support for other data types as needed, and/or consider allowing
    // dataType to be undefined?
    if (!dataType) {
      return null;
    }

    return {
      id: field,
      label: AccountFieldLabels[field],
      dataType,
      source: 'keyplay',
    };
  } else {
    const customField = fieldDefinitions.find((fieldDefinition) =>
      fieldDefinition.id.equals(field)
    );

    if (!customField) {
      return null;
    }

    return {
      id: customField.id,
      label: customField.label,
      dataType: customField.dataType,
      source: 'custom',
    };
  }
}
